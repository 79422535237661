import cn from 'classnames';
import { useMemo } from 'react';
import { getDocumentFields, getImage, getItemsByType } from 'utils/helper';
import LinkWrapper from 'UI/components/LinkWrapper';
import SectionTitle from 'UI/components/SectionTitle';
import Link from 'next/link';
import styles from './styles.module.scss';

const Works = ({ section }) => {
  const {
    title,
    description,
    contentModules,
  } = useMemo(() => getDocumentFields(
    section,
    [
      'title',
      'description',
      'contentModules',
    ],
  ), [section]);

  const [projectsData] = getItemsByType(
    contentModules,
    'block',
    [
      'contentModules',
    ],
  );

  const [link] = getItemsByType(
    contentModules,
    'link',
    ['buttonTitle', 'url'],
  );

  const projects = useMemo(() => projectsData?.contentModules.map((project) => {
    const {
      title: projectTitle,
      description: projectDescription,
      images,
      contentList,
    } = getDocumentFields(project, [
      'title',
      'description',
      'images',
      'contentList',
    ]);
    const { url: video } = getImage(images?.[0]);

    return {
      projectTitle,
      projectDescription,
      video,
      path: contentList?.[0] || '',
    };
  }), [projectsData]);

  return (
    <section className={styles.section}>
      <SectionTitle
        title={title}
        description={description}
        className={styles.titleStyle}
      />
      <div className={styles.contentWrapper}>
        {(projects || []).map(({
          path,
          video,
          ref,
          projectTitle,
          projectDescription,
        }, index) => (
          <Link
            key={path}
            href={path}
          >
            {/* eslint-disable-next-line react/jsx-no-target-blank,jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
            <a className={styles['project-wrapper']}>
              <video
                ref={ref}
                key={path}
                muted
                loop
                autoPlay
                playsInline
                className={cn(styles.project, styles[`project-${index + 1}`])}
              >
                <source
                  src={video}
                  type="video/mp4"
                />
              </video>
              <div className={styles.overlay}>
                <h4>{projectTitle}</h4>
                <p>{projectDescription}</p>
              </div>
            </a>
          </Link>
        ))}
      </div>
      {link && (
        <LinkWrapper
          path={link.url}
          className={styles.link}
        >
          {link.buttonTitle}
        </LinkWrapper>
      )}
    </section>
  );
};

export default Works;
